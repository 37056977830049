
import EventsReportContainer from "@/components/gateReport/EventsReportContainer"
import { camelizeKeys } from "humps"
import { useProjectStore } from "@/stores/project"
import { useGateReportStore } from "@/stores/gateReport"
import { mapStores } from "pinia"
import { updateQuery } from "@evercam/shared/utils"
import axios from "@evercam/shared/api/client/axios"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
} from "@evercam/shared/types/analytics"
import { useLayoutStore } from "@/stores/layout"
import { InfoPage } from "@evercam/shared/types"

export default {
  meta: {
    pageId: AnalyticsEventPageId.GateReport,
  },
  components: {
    EventsReportContainer,
  },
  middleware({ redirect, $permissions }) {
    if (
      !$permissions.user.can.access.gateReport() ||
      !$permissions.project.has.gateReport() ||
      $permissions.project.has.go()
    ) {
      redirect(`${useProjectStore().projectRoute}/info/${InfoPage.GateReport}`)
    }
    useGateReportStore().$reset()
  },
  async asyncData({ query }) {
    const gateReportStore = useGateReportStore()
    query = camelizeKeys(query)

    // populate store with data from query parameters
    const getArrayParam = (param) => (Array.isArray(param) ? param : [param])

    if (query.cameras) {
      gateReportStore.selectedCameras = getArrayParam(query.cameras)
    }
    if (query.vehicleTypes) {
      gateReportStore.selectedVehicleTypes = getArrayParam(query.vehicleTypes)
    }
    if (query.eventTypes) {
      gateReportStore.selectedEventTypes = getArrayParam(query.eventTypes)
    }
    gateReportStore.selectedDate = query.selectedDate
    gateReportStore.sort = query.sort || "event_time|asc"
    gateReportStore.showThumbnails = query.showThumbnails !== "false"
    gateReportStore.plateNumber = query.plateNumber

    const [sortBy, sortDesc] = gateReportStore.sort?.split("|") || []
    gateReportStore.options = {
      sortBy: [sortBy],
      sortDesc: [sortDesc === "desc"],
    }

    if (query.page) {
      gateReportStore.options = {
        ...gateReportStore.options,
        page: parseInt(query.page),
      }
    }
  },
  async fetch() {
    const gateReportStore = useGateReportStore()
    await gateReportStore.init()
  },
  head() {
    return {
      title: `Evercam | ${this.$t("pages.gate_report")}`,
      htmlAttrs: {
        class: "e-overflow-y-hidden",
      },
    }
  },
  computed: {
    ...mapStores(useGateReportStore, useProjectStore, useLayoutStore),
  },
  watch: {
    "gateReportStore.showThumbnails"(val) {
      updateQuery({
        showThumbnails: val,
      })
    },
    "gateReportStore.selectedDate"(val) {
      if (this.$moment(val).isValid()) {
        updateQuery({
          selectedDate: val,
        })
      }
    },
    "gateReportStore.selectedCameras"(val) {
      updateQuery({
        cameras: val,
      })
    },
    "gateReportStore.selectedVehicleTypes"(val) {
      if (this.gateReportStore.isAnprLegacy) {
        return
      }

      updateQuery({
        vehicleTypes: val,
      })
    },
    "gateReportStore.selectedEventTypes"(val) {
      updateQuery({
        eventTypes: val,
      })
    },
    "gateReportStore.options": {
      handler(options) {
        updateQuery({
          page: options.page,
        })
      },
      deep: true,
    },
    "gateReportStore.sort"(val) {
      updateQuery({
        sort: val,
      })
    },
    "gateReportStore.plateNumber"(val) {
      updateQuery({
        plateNumber: val,
      })
    },
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
  },
  methods: {
    handleDateChange() {
      axios.cancelRequests()
      this.gateReportStore.options = {
        ...this.gateReportStore.options,
        page: 1,
      }
      this.gateReportStore.isFetchingEvents = true
      this.gateReportStore.fetchEvents()
    },
    async handleOptionsChange() {
      this.gateReportStore.isFetchingEvents = true
      await this.gateReportStore.fetchEvents()
    },
  },
}


import Vue from "vue"
import type { PropType } from "vue"
import {
  Camera,
  Project,
  CameraExid,
  ImageQuality,
} from "@evercam/shared/types"
import { CameraFeatureFlag, NvrModel } from "@evercam/shared/types"
import TimelinePlayerCameraSelector from "@evercam/shared/components/timelinePlayer/TimelinePlayerCameraSelector"
import EvercamPlayer from "@evercam/shared/components/EvercamPlayer.vue"

export default Vue.extend({
  name: "SnapshotPreview",
  components: {
    EvercamPlayer,
    TimelinePlayerCameraSelector,
  },
  props: {
    camera: {
      type: Object as PropType<Camera>,
      default: () => ({} as Camera),
    },
    project: {
      type: Object as PropType<Project>,
      default: () => ({} as Project),
    },
    numberOfFrames: {
      type: Number,
      default: () => 20,
    },
    title: {
      type: String,
      default: () => "",
    },
    timestamp: {
      type: String,
      default: () => null,
    },
    startDate: {
      type: String,
      default: () => null,
    },
    endDate: {
      type: String,
      default: () => null,
    },
    showSnapshotQuality: {
      type: Boolean,
      default: false,
    },
    authToken: {
      type: String,
      default: "",
    },
    videoDuration: {
      type: Number,
      default: 2,
    },
    selectableCameras: {
      type: Array as PropType<Camera[]>,
      default: () => [],
    },
    showSelectableCameras: {
      type: Boolean,
      default: true,
    },
    showRecordingsButton: {
      type: Boolean,
      default: true,
    },
    initialSnapshotQuality: {
      type: String as PropType<ImageQuality>,
      default: ImageQuality.Auto,
    },
  },
  data() {
    return {
      isDialogOpened: false,
      selectedCamera: this.camera,
      playerDimensions: {} as { width: string; height: string },
      isPlaying: false,
    }
  },
  computed: {
    isEdgeVideo(): boolean {
      return (
        this.selectedCamera?.nvrModel === NvrModel.ExNvr &&
        this.selectedCamera?.featureFlags?.includes(CameraFeatureFlag.EdgeVideo)
      )
    },
    selectedCameraExid(): CameraExid {
      return this.selectedCamera.exid || this.selectedCamera.id
    },
    defaultSelectableCameras(): Camera[] {
      if (!this.showSelectableCameras) {
        return []
      }
      if (this.selectableCameras.length > 0) {
        return this.selectableCameras
      }

      return (this.project.cameras as Camera[]).filter(
        (c) =>
          c?.featureFlags?.includes(CameraFeatureFlag.ANPR) ||
          c?.featureFlags?.includes(CameraFeatureFlag.GateReport)
      )
    },
    intervalDuration(): number {
      if (this.isEdgeVideo) {
        return this.videoDuration
      }

      const framesPerMinute = this.camera?.cloudRecordings?.frequency ?? 12

      return this.numberOfFrames / framesPerMinute
    },
    previewInterval(): { start: string; end: string } {
      if (this.startDate && this.endDate) {
        return {
          start: this.startDate as string,
          end: this.endDate as string,
        }
      }
      const start = this.$moment
        .utc(this.timestamp)
        .subtract({ minutes: this.intervalDuration / 2 })
        .format()
      const end = this.$moment
        .utc(this.timestamp)
        .add({ minutes: this.intervalDuration / 2 })
        .format()

      return {
        start: start,
        end: end,
      }
    },
    edgeStreamingConfig(): Record<string, string> {
      const apiUrl = `https://${this.selectedCamera?.nvrHost}:${this.selectedCamera?.nvrHttpPort}`

      return {
        apiUrl,
        deviceId: this.selectedCamera?.nvrDeviceId,
        streamingUrl: `${apiUrl}${
          this.selectedCamera?.streamEndpoint ||
          this.selectedCamera?.nvrStreamingEndpoint
        }`,
        snapshotUrl: `${apiUrl}${
          this.selectedCamera?.nvrPictureEndpoint ||
          this.selectedCamera?.nvrSnapshotEndpoint
        }`,
      }
    },
    recordingsUrl(): string {
      const recordingsTimestamp = this.$moment
        .utc(this.timestamp)
        .tz(this.project?.timezone)
        ?.format("YYYY-MM-DDTHH:mm:ssZ")
      const baseUrl =
        this.$config.public.dashURL || `${window.location.origin}/v2`

      return `${baseUrl}/projects/${this.project?.exid}/${this.selectedCameraExid}/recordings/snapshots/${recordingsTimestamp}`
    },
  },
  watch: {
    camera: {
      async handler(newVal) {
        this.selectedCamera = newVal
      },
      immediate: true,
    },
    isDialogOpened: {
      async handler(value) {
        if (value) {
          this.updatePlayerDimensions()
        }
      },
      immediate: true,
    },
  },

  methods: {
    updatePlayerDimensions() {
      if (!this.isDialogOpened) {
        return
      }
      const main = this.$refs.playerContainer as HTMLDivElement
      if (!main) {
        this.$setTimeout(() => {
          this.updatePlayerDimensions()
        }, 100)

        return
      }
      const containerTop = main.getBoundingClientRect().top
      this.playerDimensions = {
        width: `${window.innerWidth}px`,
        height: `${window.innerHeight - containerTop}px`,
      }
    },
  },
})


import Vue from "vue"
import { mapStores } from "pinia"
import { useGateReportStore } from "@/stores/gateReport"
import {
  TimelineChartType,
  TimelineEvent,
  TimelineEventsByType,
  TimelineEventsGroup,
} from "@evercam/ui"
import {
  AnalyticsEvent,
  DailyEventCount,
  EventsCount,
} from "@evercam/shared/types"

export default Vue.extend({
  name: "CountsTimeline",
  data() {
    return {
      isAnimating: false,
      timelineInterval: { startDate: undefined, endDate: undefined },
      currentVisibleInterval: { startDate: undefined, endDate: undefined },
      initialSelectedDate: null,
    }
  },
  computed: {
    ...mapStores(useGateReportStore),
    isEntries(): boolean {
      return this.gateReportStore.showInEvents
    },
    isEventCounts() {
      return this.gateReportStore.eventCounts?.length !== 0
    },
    isExits(): boolean {
      return this.gateReportStore.showOutEvents
    },
    timelineEventsGroups(): TimelineEventsByType {
      return {
        entries: this.timelineEntriesEventsGroup,
        exits: this.timelineExitsEventsGroup,
      }
    },
    timelineEntriesEventsGroup(): TimelineEventsGroup {
      return {
        label: "Entries",
        color: "#1976D2",
        chartType: TimelineChartType.BarChart,
        isHidden: !this.isEntries,
        events: this.timelineDaysEntryEvents || [],
      }
    },
    timelineExitsEventsGroup(): TimelineEventsGroup {
      return {
        label: "Exits",
        color: "#d98031",
        chartType: TimelineChartType.BarChart,
        isHidden: !this.isExits,
        events: this.timelineDaysExitEvents || [],
      }
    },
    timelineDaysEntryEvents(): TimelineEvent[] {
      return this.gateReportStore.eventCounts?.days?.map(
        (day: DailyEventCount) => {
          const d = this.$moment(day.day)

          return {
            startDate: d.startOf("day").format(),
            endDate: d.endOf("day").format(),
            className: `gr-count ${
              this.isDaySelected(d) ? "gr-count--selected" : ""
            }`,
            count: this.getTotalCounts(day, true),
          }
        }
      )
    },
    timelineDaysExitEvents(): TimelineEvent[] {
      return this.gateReportStore.eventCounts?.days?.map(
        (day: DailyEventCount) => {
          const d = this.$moment(day.day)

          return {
            startDate: d.startOf("day").format(),
            endDate: d.endOf("day").format(),
            className: `gr-count ${
              this.isDaySelected(d) ? "gr-count--selected" : ""
            }`,
            count: this.getTotalCounts(day, false),
          }
        }
      )
    },
  },
  watch: {
    "gateReportStore.selectedDate": {
      immediate: true,
      handler(d) {
        if (!this.initialSelectedDate) {
          this.initialSelectedDate = d
        } else {
          this.isAnimating = true
        }

        this.$setTimeout(() => {
          if (this.currentVisibleInterval.startDate) {
            this.isAnimating = false
            this.initialSelectedDate = d
            this.timelineInterval = { ...this.currentVisibleInterval }
          }
        }, 500)
      },
    },
  },
  mounted() {
    this.timelineInterval = this.getInitialInterval()
  },
  methods: {
    onVisibleIntervalChange({ fromDate, toDate }) {
      this.currentVisibleInterval = { startDate: fromDate, endDate: toDate }
    },
    getInitialInterval(): { startDate: string; endDate: string } {
      const selectedDate = this.$moment(this.gateReportStore.selectedDate)
      const today = this.$moment().add(2, "days")
      let endDate = this.$moment(selectedDate).add(15, "days")

      if (endDate.isAfter(today)) {
        endDate = today
      }

      let startDate = this.$moment(endDate).subtract(30, "days")

      return {
        startDate: startDate.format(),
        endDate: endDate.format(),
      }
    },
    getTotalCounts(counts: EventsCount, isEntries: boolean): number {
      return (isEntries ? counts.in : counts.out) || 0
    },
    isDaySelected(d: Date) {
      return (
        this.$moment(d).format("YYYY-MM-DD") ===
        this.$moment(this.initialSelectedDate).format("YYYY-MM-DD")
      )
    },
    onDateSelected(date: string) {
      this.gateReportStore.selectedDate =
        this.$moment(date).format("YYYY-MM-DD")
      this.$analytics.saveEvent(AnalyticsEvent.GateReportTimelineDateClick)
      this.$emit("date-change")
    },
  },
})

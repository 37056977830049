
export default {
  name: "AnprVehicleDetails",
  inject: ["event"],
  data() {
    return {
      isVisibleTooltip: false,
    }
  },
  watch: {
    isVisibleTooltip(val) {
      if (val) {
        this.$emit("open-tooltip")
      }
    },
  },
}


import { debounce } from "@evercam/shared/utils"
import { mapStores } from "pinia"
import { useGateReportStore } from "@/stores/gateReport"
import { useProjectStore } from "@/stores/project"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"
import { GateReportEventType } from "@evercam/shared/types"

export default {
  name: "InputFitlers",
  computed: {
    ...mapStores(useGateReportStore, useProjectStore),
    isAnprReport() {
      return (
        this.gateReportStore.showAnprBasedGateReport ||
        this.gateReportStore.isAnprLegacy
      )
    },
    hasMultipleCameras() {
      return this.gateReportStore.cameras?.length > 1
    },
    allCamerasSelected() {
      return (
        this.gateReportStore.selectedCameras?.length ===
        this.gateReportStore.cameras?.length
      )
    },
    allVehicleTypesSelected() {
      return (
        this.gateReportStore.filteredVehicleTypes &&
        this.gateReportStore.filteredVehicleTypes.length ===
          this.gateReportStore.vehicleTypes.length
      )
    },
    allEventTypesSelected() {
      return (
        this.gateReportStore.selectedEventTypes.length ===
        this.eventTypes.length
      )
    },
    eventTypes() {
      return [
        {
          value: GateReportEventType.Arrived,
          label: "Vehicle Entry",
          disabled:
            this.gateReportStore.selectedEventTypes?.length === 1 &&
            this.gateReportStore.showInEvents,
        },
        {
          value: GateReportEventType.Left,
          label: "Vehicle Exit",
          disabled:
            this.gateReportStore.selectedEventTypes?.length === 1 &&
            this.gateReportStore.showOutEvents,
        },
      ]
    },
    plateNumber: {
      get() {
        return this.gateReportStore.plateNumber
      },
      set(value) {
        this.gateReportStore.plateNumber = value
        this.handlePlateNumberChange()
      },
    },
    camerasOptions() {
      return this.gateReportStore.cameras?.map((camera) => ({
        value: camera.id,
        label: camera.name,
      }))
    },
  },

  methods: {
    handleCameraChange: debounce(function () {
      this.$analytics.saveEvent(AnalyticsEvent.GateReportFilterCameras, {
        selectedCameras: this.gateReportStore.selectedCameras.join(","),
      })
      this.$emit("filters-change")
    }, 500),

    handleVehicleTypeChange: debounce(function () {
      this.$analytics.saveEvent(AnalyticsEvent.GateReportFilterVehicleTypes, {
        selectedVehicleTypes:
          this.gateReportStore.filteredVehicleTypes.join(","),
      })
      this.$emit("filters-change")
    }, 500),
    handleEventTypesChange() {
      this.$analytics.saveEvent(AnalyticsEvent.GateReportFilterEntryExit, {
        selectedEventTypes: this.gateReportStore.selectedEventTypes.join(","),
      })
      this.$emit("filters-change")
    },
    handlePlateNumberChange: debounce(function () {
      this.$analytics.saveEvent(AnalyticsEvent.GateReportFilterPlateNumber, {
        plateNumber: this.gateReportStore.plateNumber,
      })
      this.$emit("filters-change")
    }, 500),
    getIconStyle(vehicleId) {
      return this.gateReportStore.selectedVehicleTypes.includes(vehicleId)
        ? "selected"
        : "unselected"
    },
  },
}

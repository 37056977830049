
import { mapStores } from "pinia"
import { useGateReportStore } from "@/stores/gateReport"
import CalendarLeaf from "@/components/gateReport/CalendarLeaf"
import { GateReportProcessingStatus } from "@evercam/api/types"

export default {
  name: "CalendarTree",
  components: { CalendarLeaf },
  props: {
    years: {
      type: Array,
      default: () => [],
    },
    months: {
      type: Array,
      default: () => [],
    },
    days: {
      type: Array,
      default: () => [],
    },
    activeNode: {
      type: String,
      default: () => "",
    },
    dailyStatus: {
      type: Object,
      default: () => ({ processed: [], unprocessed: [] }),
    },
  },
  computed: {
    ...mapStores(useGateReportStore),
    filteredTree() {
      return this.tree.map((yearNode) => ({
        ...yearNode,
        children: this.getFilteredMonths(yearNode),
        locked: this.getFilteredMonths(yearNode).length === 0,
      }))
    },
    statusByDay() {
      return Object.entries(this.dailyStatus).reduce((acc, [status, days]) => {
        const statusDays = days.reduce((acc, d) => {
          return {
            ...acc,
            [d]: status,
          }
        }, {})

        return { ...acc, ...statusDays }
      }, {})
    },
    openNodes() {
      const date = this.$moment(this.gateReportStore.selectedDate)
      const dateFormat = this.$moment(this.gateReportStore.selectedDate)._f

      if (dateFormat?.includes("DD")) {
        return [
          date.format("YYYY"),
          date.format("YYYY-MM"),
          date.format("YYYY-MM-DD"),
        ]
      } else if (dateFormat?.includes("MM")) {
        return [date.format("YYYY"), date.format("YYYY-MM")]
      } else {
        return [date.format("YYYY")]
      }
    },
  },
  beforeMount() {
    this.tree = this.getInitialTree()
  },
  methods: {
    getInitialTree() {
      const startYear = 2019
      const today = new Date()
      let tree = []
      let date = this.$moment([startYear])
      while (date < today) {
        tree.push({
          id: date.format("YYYY"),
          name: date.year(),
          children: [...new Array(12)].reduce((months) => {
            if (date > today) {
              return months
            }
            const month = {
              id: date.format("YYYY-MM"),
              name: date.format("MMM"),
              children: [...new Array(date.daysInMonth())].reduce((days) => {
                if (date > today) {
                  return days
                }
                let day = this.getDayItem(date)
                date.add(1, "days")

                return [...days, day]
              }, []),
            }

            return [...months, month]
          }, []),
        })
      }

      return tree
    },
    getDayItem(date) {
      const formattedDay = date.format("YYYY-MM-DD")

      return {
        id: formattedDay,
        name: date.format("DD"),
        weekDay: date.format("dd")[0],
        startOfWeek:
          date.diff(this.$moment(date).startOf("isoWeek"), "days") === 0,
        endOfWeek: date.diff(this.$moment(date).endOf("isoWeek"), "days") === 0,
      }
    },
    getFilteredMonths(year) {
      if (!this.months.length) {
        return []
      }
      let filteredMonths = []
      const resultMonths = this.getMonthsForYear(year.id)
      if (resultMonths.length) {
        filteredMonths = year.children.filter((month) => {
          return this.$moment(month.id).isBetween(
            resultMonths[0].month,
            resultMonths.slice(-1)[0].month,
            null,
            "[]"
          )
        })
      }

      return filteredMonths
    },
    getMonthsForYear(year) {
      return this.months
        .filter((month) => {
          return this.$moment(month.month).year() === this.$moment(year).year()
        })
        .sort((m1, m2) => {
          return m1.month < m2.month ? -1 : 1
        })
    },
    getLabelData(date) {
      const dateObj = this.$moment(date)
      const format = dateObj.creationData().format
      let item = {}

      if (format === "YYYY-MM-DD") {
        item = this.days.find((d) => d.day === date) || {}
        item = {
          ...item,
          status:
            this.statusByDay[date] || GateReportProcessingStatus.Unprocessed,
        }
      } else if (format === "YYYY-MM") {
        item = this.months.find((data) => data.month === date) || {}
      } else if (format === "YYYY") {
        item = this.years.find((data) => data.year === date) || {}
      }

      return {
        inCount: item?.in || 0,
        outCount: item?.out || 0,
        unknownCount: item?.unknown || 0,
        status: item?.status || GateReportProcessingStatus.Processed,
      }
    },
  },
}


import { AnalyticsEvent } from "@evercam/shared/types"

import EventThumbnail from "@/components/gateReport/eventsTable/EventThumbnail"
import EventTimeCell from "@/components/gateReport/eventsTable/EventTimeCell"
import AnprVehicleDetails from "@/components/gateReport/eventsTable/AnprVehicleDetails.vue"
import PlateNumber from "@/components/anpr/PlateNumber"

export default {
  name: "AnprBasedGateReportTableRow",
  components: {
    AnprVehicleDetails,
    EventThumbnail,
    EventTimeCell,
    PlateNumber,
  },
  inject: [
    "event",
    "eventType",
    "eventCameraName",
    "eventTruckType",
    "eventTruckTypeId",
    "eventTypeIconStyle",
    "headers",
    "visibleHeaders",
    "eventPlateNumber",
  ],
  data() {
    return {
      rowHeight: 100,
    }
  },
  methods: {
    onOpenVehicleDetailTooltip(event) {
      this.$analytics.saveEvent(AnalyticsEvent.GateReportOpenVehicleTypeInfo, {
        gateReportEvent: {
          eventTime: event.eventTime,
          id: event.id,
          cameraExid: event.cameraExid ?? "",
          contextCamera: event.contextCamera ?? "",
          vehicleType: event.vehicleType ?? "",
          contextVehicleType: event.contextVehicleType ?? "",
        },
      })
    },
    updateRowHeight(height) {
      this.rowHeight = height
    },
  },
}


import anprLegacyHeaders from "@evercam/dashboard/components/anpr/anprLegacyHeaders"
import TableRowWrapper from "@evercam/dashboard/components/gateReport/eventsTable/TableRowWrapper"
import EventsUtils from "@/components/gateReport/mixins/eventsUtils"
import { decamelize } from "humps"
import { useGateReportStore } from "@/stores/gateReport"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { CameraFeatureFlag } from "@evercam/shared/types"

export default {
  name: "EventsTable",
  components: {
    TableRowWrapper,
  },
  mixins: [EventsUtils],
  data() {
    return {
      height: "100%",
      sortBy: null,
      sortDesc: true,
    }
  },
  computed: {
    ...mapStores(useGateReportStore, useProjectStore),
    headers() {
      if (this.gateReportStore.isAnprLegacy) {
        const headers = anprLegacyHeaders(this)

        return headers.reduce(
          (acc, header) => ({ ...acc, [header.value]: header }),
          {}
        )
      }

      return {
        thumbnail: {
          isThumbnail: true,
          visible: this.gateReportStore.showThumbnails,
          divider: true,
          sortable: false,
          text: !this.gateReportStore.showLegacyGateReport
            ? "ANPR"
            : this.$t("content.gate_report.thumbnail"),
          class: "header--custom",
          align: "center",
          width: "1%",
        },
        contextThumbnail: {
          isThumbnail: true,
          isContext: true,
          visible:
            !this.gateReportStore.showLegacyGateReport &&
            this.gateReportStore.showThumbnails,
          divider: true,
          sortable: false,
          text: this.$t("content.gate_report.thumbnail_context"),
          class: "header--custom",
          align: "center",
          width: "1%",
        },
        plateNumber: {
          visible:
            this.gateReportStore.showAnprBasedGateReport &&
            !this.gateReportStore.hidePlateNumberColumn,
          divider: true,
          sortable: true,
          text: this.$t("content.gate_report.plate_number"),
          value: "plateNumber",
          class: "header--custom",
          align: "center",
        },
        cameraExid: {
          visible: true,
          divider: true,
          sortable: true,
          text: this.$t("content.gate_report.camera"),
          value: "cameraex",
          class: "header--custom",
          align: "center",
        },
        eventTime: {
          visible: true,
          divider: true,
          sortable: true,
          text: this.$t("content.gate_report.event_time"),
          value: "eventTime",
          class: "header--custom",
          align: "center",
        },
        eventType: {
          visible: true,
          divider: true,
          sortable: true,
          text: this.$t("content.gate_report.event_type"),
          value: "eventType",
          class: "header--custom",
          align: "center",
        },
        truckType: {
          visible: true,
          divider: true,
          sortable: true,
          text: this.$t("content.gate_report.vehicle_type"),
          value: "truckType",
          class: "header--custom",
          align: "center",
        },
      }
    },
    visibleHeaders() {
      return Object.values(this.headers).reduce((acc, header) => {
        return header.visible ? [...acc, header] : acc
      }, [])
    },
    projectCameras() {
      return this.projectStore.selectedProjectCameras
        .filter(
          (camera) =>
            camera?.featureFlags?.includes(CameraFeatureFlag.GateReport) ||
            camera?.featureFlags?.includes(CameraFeatureFlag.ANPR)
        )
        .reduce((acc, c) => {
          return {
            ...acc,
            [c.exid || c.id]: c,
          }
        }, {})
    },
  },
  watch: {
    "gateReportStore.sort"(newVal, oldVal) {
      if (newVal === oldVal || this.gateReportStore.isAnprLegacy) {
        return
      }
      this.$emit("options-change")
    },
    "gateReportStore.options": {
      handler(options, OldOptions) {
        if (options?.page !== OldOptions?.page) {
          this.$emit("options-change")

          return
        }
        if (this.gateReportStore.isAnprLegacy) {
          return
        }
        const { sortBy, sortDesc } = options
        if (sortBy[0] === undefined || sortDesc[0] === undefined) {
          return
        }
        this.sortBy = sortBy
        this.sortDesc = sortDesc
        this.gateReportStore.sort = `${decamelize(sortBy[0])}|${
          sortDesc[0] ? "desc" : "asc"
        }`
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.onResize()
    })

    if (!this.gateReportStore.sort?.length) {
      return
    }
    const [sortBy, sortDesc] = (this.gateReportStore.sort || "").split("|")
    this.sortBy = sortBy
    this.sortDesc = sortDesc === "desc"
  },
  methods: {
    onResize() {
      const padding = 20
      const tableContainerTopPosition =
        this.$refs.tableContainer?.getBoundingClientRect().top
      const newHeight = window.innerHeight - tableContainerTopPosition - 35
      this.height = newHeight - padding
    },
  },
}
